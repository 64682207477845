import React, {FC, useState} from 'react';
import Paper from "@mui/material/Paper";
import {Box, Chip, Stack, useMediaQuery} from "@mui/material";
import imgCopy from "../../shared/assets/images/tariff-copy.svg";
import imgRobot from "../../shared/assets/images/tariff-robot.svg";
import imgFound from "../../shared/assets/images/tariff-found.svg";
import AddProductModal from "./modal/addProductModal";
import 'swiper/css';
import {Swiper, SwiperSlide} from "swiper/react";

interface IType {
    children?: any
}

const state = [
    {
        id: 3,
        name: 'Робот Aggressive',
        priceTitle: '',
        image: imgRobot,
        price: '1.700₽',
        slug: null,
        type: 'forex',
        text: 'Робот работает по двум стратегиям: откат в тренде и усреднение при развороте цены. Он автоматически открывает ордера при изменении цены в направлении тренда и закрывает их с прибылью при развороте. Это максимизирует возможность прибыли.',
        sale: {
            isSale: false,
            priceSale: '$10 000',
            text: 'Скидка'
        }
    },
    // {
    //     id: 4,
    //     name: 'Робот Conservative',
    //     priceTitle: '',
    //     image: imgRobot,
    //     price: '1.700₽',
    //     slug: null,
    //     type: 'forex',
    //     text: 'Робот торгует по новой авторской стратегии с большим количеством ордеров с относительно маленькими объемами.',
    //     sale: {
    //         isSale: false,
    //         priceSale: '$10 000',
    //         text: 'Скидка'
    //     }
    // },
    {
        id: 5,
        name: 'FondMaker',
        priceTitle: '',
        image: imgFound,
        price: '1.000₽',
        slug: null,
        type: 'stock',
        text: 'Профессиональная торговая панель для безопасного инвестирования на Московской Фондовой Бирже',
        sale: {
            isSale: false,
            priceSale: '$10 000',
            text: 'Скидка'
        }
    },
    // {
    //     id: 6,
    //     name: 'FondMaker в будущем',
    //     priceTitle: '',
    //     image: imgFound,
    //     price: '35.000₽',
    //     slug: null,
    //     text: 'Velit nunc ultrices sit est et varius. Tellus accumsan pretium sollte non sed arcu. Justo ut nisl tincidun',
    //     sale: {
    //         isSale: false,
    //         priceSale: '$10 000',
    //         text: 'Скидка'
    //     }
    // },
]

const ProductItem: FC<IType> = ({children}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [stateModal, setStateModal] = useState(state[0])
    const [openModal, setOpenModal] = useState(false);

    return (
        <Stack direction={mediaQuery ? "row" : "column"} spacing={7}>
            {
                state.map(item =>
                    <Paper
                        onClick={() => {
                            setStateModal(item)
                            setOpenModal(true)
                        }}
                        key={item.id}
                        sx={{
                            maxWidth: 300,
                            padding: '14px !important',
                            background:
                                ((item.id === 3) && `linear-gradient(180deg, #33223A 0%, rgba(51, 34, 58, 0) 93.75%)`) ||
                                ((item.id === 4) && `linear-gradient(180deg, #242D28 0%, rgba(36, 45, 40, 0) 93.75%)`) ||
                                ((item.id === 5) && `linear-gradient(180deg, #7e7241 0%, rgba(36, 45, 40, 0) 93.75%)`) ||
                                '#fff',
                            cursor: 'pointer',
                            "@media (max-width:980px)": {
                                maxWidth: '100%',
                                width: '100%',
                                minHeight: '345px'
                            }
                        }}>
                        <Stack spacing={2}>
                    <span
                        className={
                            ((item.id === 3) && "h1 pink") ||
                            ((item.id === 4) && "h1 green") ||
                            ((item.id === 5) && "h1 yellow") ||
                            "h1 green"}
                    >{item.name}</span>
                            <span className="h2">{item.priceTitle}</span>
                            <p className="subHeaders white-90">{item.text}</p>
                            <Stack alignItems="center" sx={{
                                "@media (max-width:980px)": {
                                    maxWidth: '100%',
                                    width: '100%',
                                    minHeight: '127px',
                                    marginTop: '49px !important'
                                }
                            }}>
                                <img src={item.image} alt="Копировальщик" style={{
                                    height: '100%',
                                    width: 'auto'
                                }}/>
                            </Stack>
                            <Chip label={item.sale.isSale ? item.price + ' ' + item.sale.priceSale : item.price}
                                  color="warning" variant="outlined" sx={{
                                position: 'absolute',
                                bottom: 14,
                                left: 14,
                            }}/>
                            {
                                item.sale.isSale &&
                                <Chip label={item.sale.text} color="error" variant="outlined" sx={{
                                    position: 'absolute',
                                    bottom: 14,
                                    right: 14,
                                }}/>
                            }
                        </Stack>
                    </Paper>
                )
            }
            {
                openModal && <AddProductModal stateModal={stateModal} openModal={openModal} closeModal={setOpenModal}/>
            }

        </Stack>
    );
};

export default ProductItem;
